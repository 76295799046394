import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
import { useMutation } from '@vue/apollo-composable';
import { ConceptStatus, useListConceptsQuery, useGetUserQuery } from '~/types/types';
import ADD_CONCEPT from '~/graphql/mutations/concept/AddConcept.gql';
export default defineComponent({
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    multiple: {
      type: Boolean,
      default: false
    },
    conceptKind: {
      type: String,
      default: 'COMPANY'
    },
    label: {
      type: String,
      default: ''
    }
  },
  setup: function setup(props) {
    var _result$value, _result$value$getUser;
    var conceptSearch = ref(null);
    var limit = 500;
    var _useListConceptsQuery = useListConceptsQuery({
        queryInput: {
          text: conceptSearch.value
        },
        kind: props.conceptKind,
        limit: limit,
        offset: 0
      }),
      conceptsResult = _useListConceptsQuery.result,
      refetchConcepts = _useListConceptsQuery.refetch,
      loadingConcepts = _useListConceptsQuery.loading;
    var concepts = computed(function () {
      var _conceptsResult$value, _conceptsResult$value2;
      return (_conceptsResult$value = (_conceptsResult$value2 = conceptsResult.value) === null || _conceptsResult$value2 === void 0 ? void 0 : _conceptsResult$value2.listConcepts) !== null && _conceptsResult$value !== void 0 ? _conceptsResult$value : [];
    });
    var _useMutation = useMutation(ADD_CONCEPT),
      addConcept = _useMutation.mutate,
      addConceptOnDone = _useMutation.onDone;
    var _useGetUserQuery = useGetUserQuery(),
      result = _useGetUserQuery.result;
    var email = (_result$value = result.value) === null || _result$value === void 0 ? void 0 : (_result$value$getUser = _result$value.getUser) === null || _result$value$getUser === void 0 ? void 0 : _result$value$getUser.email;
    return {
      concepts: concepts,
      conceptSearch: conceptSearch,
      refetchConcepts: refetchConcepts,
      loadingConcepts: loadingConcepts,
      addConcept: addConcept,
      addConceptOnDone: addConceptOnDone,
      email: email,
      limit: limit
    };
  },
  data: function data() {
    return {
      toAddConcepts: [],
      _searchTimerId: undefined,
      isNew: false
    };
  },
  computed: {
    sortedItems: function sortedItems() {
      return _toConsumableArray(this.concepts).sort(function (a, b) {
        var _b$impacts, _a$impacts;
        return ((_b$impacts = b.impacts) === null || _b$impacts === void 0 ? void 0 : _b$impacts.length) - ((_a$impacts = a.impacts) === null || _a$impacts === void 0 ? void 0 : _a$impacts.length);
      });
    }
  },
  watch: {
    conceptSearch: function conceptSearch(val) {
      if (!val || val === null) return;
      this.fetchEntriesDebounced(this.fetchConcepts);
    }
  },
  methods: {
    fetchEntriesDebounced: function fetchEntriesDebounced(func) {
      clearTimeout(this._searchTimerId);
      this._searchTimerId = setTimeout(function () {
        func();
      }, 500);
    },
    fetchConcepts: function fetchConcepts() {
      this.refetchConcepts({
        label: this.conceptSearch,
        kind: this.conceptKind,
        limit: this.limit
      });
    },
    isVerified: function isVerified(status) {
      if ([ConceptStatus.Verified, ConceptStatus.Autoverified].includes(status)) return true;else return false;
    },
    checkSelected: function checkSelected(val) {
      // TODO handle multiple
      this.isNew = typeof val === 'string';
      if (!this.isNew) this.$emit('change', val);
    },
    create: function create() {
      var _this = this;
      var input = {
        label: this.conceptSearch,
        status: 'PROPOSED',
        kind: this.conceptKind,
        createdBy: this.email
      };
      this.addConcept({
        input: input
      });
      this.addConceptOnDone(function (res) {
        _this.$emit('change', res.data.addConcept);
        _this.isNew = false;
      });
    },
    reset: function reset() {
      if (this.$refs.combobox) this.$refs.combobox.reset();
      this.isNew = false;
      this.$emit('change', null);
    }
  }
});